:root {
  --primary: #272262;
  --primary-transparent: #272262dd;
  --secondary: #f5216f;
  --primary-solid: #ff5401;
  --secondary-solid: #ff5401;
  --dark-solid: #00296b;
  --white: #f7f7f7;
  --black: #191919;
}
