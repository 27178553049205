.app__jobs-hero {
  background-image: url('https://images.unsplash.com/photo-1531972111231-7482a960e109?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80');
  height: 32rem;
  background-size: cover;
  background-position: bottom center;
}

.app__jobs-subheading {
  text-align: left;
  margin-left: 80px;
}

.app__jobs-subheading h2 {
  font-weight: 400;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: -0.015em;
  color: #000000;
}

.app__jobs-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  color: white;
  margin-left: 2rem;
  margin-right: 2rem;
}

.app__jobs-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 18px 24px;
  width: 111px;
  height: 60px;
  border: 1px solid black;
  flex: none;
  background-color: #272262;
}

.app__jobs-button a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-decoration: none;
  color: white;

  font-family: 'Montserrat', sans-serif;
}

.app__jobs-button:hover {
  background-color: #ff216d;
}
