@import './variables.scss';
// @import '~react-image-gallery/styles/scss/image-gallery.scss';

body {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  color: (var(--primary));
}

h2 {
  color: (var(--secondary));
  margin-bottom: 2rem;
}

p {
  color: (var(--black));
}

.image-gallery {
  @media (max-width: 900px) {
    width: 100%;
  }
}

.custom-gallery {
  @media (max-width: 900px) {
    margin-top: 7rem;
  }
}
.single-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
