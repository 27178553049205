.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 900px) {
    margin-top: 125px;
  }
}

.btn-upload-resume {
  margin: 1.5rem 1.2rem;
  background-color: #ff216d;
  border: none;
  font-family: 'Montserrat', sans-serif;
  &:hover {
    background-color: #272262;
  }
}

.alumni-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.alumni-cards-container {
  display: flex;
  flex-wrap: wrap;
}

.alumni-card {
  width: 284px;
  height: auto;
  border-radius: 20px;
  transition: 0.3s;
  margin: 20px;
  box-shadow: 3px 3px 5px 0 rgb(50 50 50 / 50%);
  position: relative;
  background-color: #fff;
}

.alumni-content {
  padding: 0px 20px 50px 20px;
}

.alumni-image {
  width: 284px;
  height: 200px;
  border-radius: 20px;
}

.alumni-about {
  margin-bottom: 3%;
  height: 80%;
}

.readmore-container {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 4%;
}

.alumni-readmore-btn {
  padding: 10px 20px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 15px;
  color: #272262;
  border: 2px solid #272262;
  font-weight: 600;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}
.resume-form {
  display: flex;
  justify-content: center;
  padding: 2rem;
}
