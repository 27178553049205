.app__jobitem {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
}

// .app__jobitem-jobListing {
//   box-sizing: border-box;
//   position: relative;
//   margin: 15px;
//   min-width: 250px;
//   max-width: 686px;
//   width: 100%;
//   background-color: #ffffff;
//   color: #000000;
//   text-align: left;
//   font-size: 18px;
//   box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
//   border-radius: 5px;
//   display: -webkit-box;
//   -webkit-line-clamp: 5; /* number of lines to show */
//   line-clamp: 5;
//   -webkit-box-orient: vertical;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   &:hover {
//     transform: scale(1.04);
//     outline-width: 6px;
//   }
// }

// .app__jobitem-jobListing h4 {
//   font-weight: 700;
//   font-size: 24px;
//   line-height: 36px;
//   letter-spacing: -0.015em;
//   text-align: left;
// }
// .app__jobitem-jobListing p {
//   font-weight: 400;
//   font-size: 18px;
//   line-height: 30px;
//   letter-spacing: -0.015em;
//   color: rgba(0, 0, 0, 0.8);
//   flex-wrap: wrap;
//   display: flex;
// }
